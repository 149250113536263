import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

/*
 * Used to dynamically swap CMS links with appropriate Gatsby links
 * Adapted from:
 * https://www.gatsbyjs.org/docs/gatsby-link/#use-link-only-for-internal-links
 */

const SmartLink = ({ children, href, activeClassName, ...other }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={({ site: { siteMetadata: seo } }) => {
      const url = seo.siteUrl
      const regex = new RegExp(`${url}[/]`, "g") // correct way

      const sameDomain = regex.test(href)

      if (sameDomain) {
        href = href.replace(regex, "/")
      }
      const internal = /^\/(?!\/|#)/.test(href)
      return internal ? (
        <Link
          to={href}
          data-link-internal
          activeClassName={activeClassName}
          {...other}
        >
          {children}
        </Link>
      ) : (
        <a
          href={href}
          target="_blank"
          data-link-external
          rel="noopener noreferrer nofollow"
          {...other}
        >
          {children}
        </a>
      )
    }}
  />
  // This assumes that any internal link (intended for Gatsby)
  // will start with one slash or a hash tag
  // console.log("href", props)

  // const internal = /^\/(?!\/|#)/.test(href)
  // // Use Gatsby Link for internal links, and <a> for others
  // return internal ? (
  //   <Link to={href} activeClassName={activeClassName} {...other}>
  //     {children}
  //   </Link>
  // ) : (
  //   <a href={href} target="_blank" rel="noreferrer" {...other}>
  //     {children}
  //   </a>
  // )
)

export default SmartLink
