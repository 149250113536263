import React from "react"

const PullQuote = ({ title, children }) => {
  // let fontSizeAdjust = "3rem"
  // if (title) {
  //   if (title.length > 15) {
  //     fontSizeAdjust = "2.5rem"
  //   } else if (title.length > 25) {
  //     fontSizeAdjust = "2.0rem"
  //   }
  // }

  return (
    <div>
      <h3
        className="is-link has-text-link has-text-centered is-size-2-tablet is-size-4"
        style={{ paddingTop: "3rem" }}
      >
        {children}
      </h3>
      <hr
        style={{
          maxWidth: "150px",
          margin: "auto",
          marginBottom: "2.5rem",
        }}
      />
    </div>
  )
}

export default PullQuote
