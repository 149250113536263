import React from "react"
// import MDX from "@mdx-js/runtime"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
// import { Img } from "gatsby-image"
import SmartLink from "../components/smartLink"
import PullQuote from "../components/pullQuote"
// Include all components that will be parsed by MDX as React components here.
// Any React component you'd like to allow your editors to use should be placed here.
export const CMS_SHORTCODES = {
  PullQuote: props => <PullQuote {...props} />,
  // Img: props => <Img {...props} />,
}

// Include any tags you'd like to replace with React components
export const CMS_COMPONENTS = {
  //   h1: props => <Heading tag={1} {...props} />,
  //   h2: props => <Heading tag={2} {...props} />,
  h3: props => <PullQuote tag={3} {...props} />,
  //   h4: props => <Heading tag={4} {...props} />,
  //   h5: props => <Heading tag={5} {...props} />,
  //   h6: props => <Heading tag={6} {...props} />,
  a: props => <SmartLink {...props} />,
}

const RenderMarkdown = ({ md }) => {
  return (
    <MDXProvider components={{ ...CMS_COMPONENTS, ...CMS_SHORTCODES }}>
      <MDXRenderer>{md}</MDXRenderer>
    </MDXProvider>
  )
  //   <MDX c>{md}</MDX>
}

export default RenderMarkdown
