import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ContactMe from "../components/contactMe"
import MainHero from "../components/mainHero"
import SEO from "../components/SEO"
import Intro from "../components/intro"
import RenderMarkdown from "../cms/renderMarkdown"

const About = ({
  data: {
    content: { frontmatter, body },
  },
}) => {
  return (
    <Layout>
      <SEO title="Counseling in Birmingham, MI | Sydney Reiter | About" />
      <MainHero
        heroImage={frontmatter.hero_image}
        title={frontmatter.title}
        subTitle={frontmatter.subTitle}
        height="is-medium"
        crop="50"
      />
      <Intro
        fixed={frontmatter.intro_headshot.childImageSharp.fixed}
        title={frontmatter.intro_title}
        subTitle={frontmatter.intro_content}
      />
      <section className="section">
        <div className="container">
          <div
            style={{
              maxWidth: "850px",
              margin: "auto",
            }}
          >
            <RenderMarkdown md={body} />
          </div>
        </div>
      </section>
      <ContactMe />
    </Layout>
  )
}

export default About

export const query = graphql`
  {
    content: mdx(frontmatter: { section: { eq: "about" } }) {
      frontmatter {
        intro_headshot {
          childImageSharp {
            fixed(width: 163, height: 253) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hero_image {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        title
        subTitle
        intro_content
        intro_title
      }
      body
    }
  }
`
