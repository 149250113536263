import React from "react"
import Img from "gatsby-image"

const Intro = ({ fixed, title, subTitle }) => {
  return (
    <div className="section">
      <div className="container">
        <div
          className="box columns"
          style={{
            maxWidth: "950px",
            margin: "auto",
          }}
        >
          <figure className="column">
            <Img
              fixed={fixed}
              style={{
                // borderRadius: "128px",

                display: "block",
                margin: "auto",
              }}
            ></Img>
          </figure>

          <div className="column is-two-thirds">
            <h2
              className="is-size-4 is-size-3-tablet has-text-primary has-text-centered"
              style={{ marginBottom: "1.2rem" }}
            >
              {title}
            </h2>
            <hr
              style={{ maxWidth: "50px", margin: "auto", marginBottom: "2rem" }}
            />
            <p className="is-size-6 is-size-5-tablet  has-text-primary">
              {subTitle}
            </p>
            <p className="is-size-5-tablet is-size-6 has-text-primary">
              — Sydney Reiter, LMSW, ACSW
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro
